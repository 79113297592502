ion-toolbar{
  --color: var(--ion-color-primary);
  --background:#FFFFFF;

  img{
    height:32px;
  }
}

ion-menu{
  ion-item{
    color: var(--ion-color-primary);

    &.light{
      color: var(--ion-color-tertiary);
    }
  }

  ion-content{
    --background: #FFFFFF;
  }
}

ion-button{
  --color:#FFFFFF;
  --border-color:var(--ion-color-secondary);
  --border-width:1px;
  --border-style:solid;
  --box-shadow:none;
  --border-radius:0;
  --padding-left:12px;
  font-weight: bold;

  &.button-clear{
    --border-width:0;
    --color:var(--ion-color-tertiary);
  }
}

ion-item{
  --background:#FFFFFF;
  --highlight-color-focused:none;
}

ion-input{
  --background:#FFFFFF;
  border: solid 1px var(--ion-color-secondary);
  margin-bottom:16px;
}


ion-breadcrumbs{
  justify-content: center;
}

ion-breadcrumb{
  --color-active: var(--ion-color-secondary);
  --color:var(--ion-color-primary);
}

ion-footer{
  text-align: center;
  padding:12px;
  color:var(--ion-color-secondary-tint);
}

.fcapp-container{
  max-width:960px;
  margin:0 auto;
}

.fcapp-category{
  position: relative;
  overflow: hidden;
  cursor:pointer;
  width:calc(50% - 16px);
  max-width:960px;
  margin-right:16px;
  margin-bottom:16px;
  float:left;
  border-radius: 21px;
  box-shadow: 1px 2px 4px #00000029;
  background-color:#FFFFFF;
  height:120px;
  display: flex;
  flex-direction: row;
  align-items: center;

  h2{
    font-size: 20px;
  }

  .fcapp-category-image{
    height:110px;
    width:110px;
    background-color: var(--ion-color-secondary-tint);
    background-position: center;
    background-size: cover;
    border-radius: 60px;
    margin:6px;
  }

  .fcapp-category-content{
    flex:1;
    padding-left:16px;
    padding-right:8px;
    text-align:center;
  }

  &:hover{
    background-color: var(--ion-color-shade);
  }
}

.fcapp-content-header{
  border-radius: 21px;
  box-shadow: 1px 2px 4px #00000029;
  background-color:#FFFFFF;
  height:120px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .fcapp-content-header-image{
    height:120px;
    width:120px;
    background-color: var(--ion-color-secondary-tint);
    background-position: center;
    background-size: cover;
  }

  .fcapp-content-header-content{
    flex:1;
    padding-left:16px;
    padding-right:8px;
  }
}

.fcapp-content{
  background-color: #FFFFFF;
  border-radius: 21px;
  box-shadow: 1px 2px 4px #00000029;
}

.fcapp-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fcapp-container-center{
  background-color: #FFFFFF;
  padding:32px 48px;
  max-width:640px;
  border-radius: 16px;
  -webkit-box-shadow: 1px 2px 4px 0px #CCCCCC;
  box-shadow: 1px 2px 4px 0px #CCCCCC;
}

.fcapp-flag{
  width:40px;
  height:21px;
  background-repeat: no-repeat;
  margin-right:16px;
}

.fcapp-flag-en{
  background: url("../assets/img/flags.png") 0 -21px;
}

.fcapp-flag-de{
  background: url("../assets/img/flags.png") 0 0;
}

.fcapp-flag-es{
  background: url("../assets/img/flags.png") 0 -42px;
}

@media only screen and (max-width: 680px) {
  .fcapp-category{
    flex-direction: column;
    height:200px;

    .fcapp-category-image{

    }

    .fcapp-category-content{
      width:100%;
      h2{
        font-size: 18px;
      }

    }
  }
}

body.dark{

  ion-card-title{
    color:#FFFFFF;
    --color:#FFFFFF;
  }

  .fcapp-container-center{
    background-color: var(--ion-color-light-shade);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  ion-card-content{
    --color:#FFFFFF;
    b{
      color:#FFFFFF !important;
      font-size:1.3rem;
    }
  }

  ion-card-subtitle{
    --color:#FFFFFF;
  }

  ion-button{
    --color:var(--ion-color-light-shade);
    --border-color:var(--ion-color-secondary);
    --border-width:1px;
    --border-style:solid;
    --box-shadow:none;
    --border-radius:0;
    margin-inline: 0;

    &.button-clear{
      --border-width:0;
      --color:var(--ion-color-tertiary);
    }
  }

  ion-card{

    --background: var(--ion-color-light-shade);
  }

  ion-toolbar{
    --background:var(--ion-color-light-shade);
  }

  .fcapp-category{
    background-color:var(--ion-color-light-shade);

    &:hover{
      background-color: var(--ion-color-light-tint);
    }
  }

  .fcapp-content,
  .fcapp-content-header{
    background-color:var(--ion-color-light-shade);
  }



  ion-item{
    --background: var(--ion-color-light-shade);
    --color-activated: var(--ion-color-light-shade);
    --highlight-color-focused:#000000;
  }

  ion-menu{

    ion-item{
      color: var(--ion-color-primary);

      &.light{
        color: var(--ion-color-tertiary);
      }
    }

    ion-content{
      --background: var(--ion-color-light);
    }

    ion-item{
      --background: var(--ion-color-light);
    }

    ion-toolbar{
      --background: var(--ion-color-light);
    }

  }
}
